<template>
    <div class="GlobalTable">
        <div style="display: flex;justify-content: flex-end;align-items: center;margin-bottom: 20px;">
            <el-button size="small" type="success" @click="handBtn1('add')">上账</el-button>
            <el-button size="small" type="danger" @click="handBtn1('sub')">下账</el-button>
            <el-button size="small" type="warning" @click="handBtn()">调账上账</el-button>
            <el-button :loading="isExport" size="small" type="success" icon="el-icon-download"
                @click="clickExcel">导出账单</el-button>
        </div>
        <GlobalTable ref="merchantTable" v-loading="loading" :columns="tableColumns1" :data="this.seachDataList"
            :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
            <el-table-column label="账单流水号" slot="uniqNo" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.uniqNo || "--" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="原始订单号" slot="orderNo" align="center">
                <template slot-scope="{ row }">
                    <div style="color: #0981ff;cursor: pointer;" @click="toOrderDetails(row)" v-if="row.orderNo">
                        <span style="border-bottom: 1px solid #0981ff">{{ row.orderNo || "- -" }}</span>
                    </div>
                    <div v-else>
                        -
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="所属门店商户" slot="shop" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.companyName || "- -" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="账单类型" slot="opt" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.opt == '01' ? '入账' : row.opt == '02' ? '出账' : row.opt == '03' ? "挂账" : "" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="账单子类型" slot="title" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column label="金额" slot="amount" align="center">
                <template slot-scope="{ row }">
                    <span
                        :style="row.serType == '05' || row.serType == '03' || row.serType == '01' ? 'color:green' : 'color:red'">{{
                            row.amount &&
                                (row.serType == '05' || row.serType == '03' || row.serType == '01') ? `+${row.amount}` :
                                `-${row.amount}` }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="当时账户结余" slot="nowBalance" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.afterBalance || "--" }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="当前红包账户余额" slot="redbageBalance" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.nowBalance || "--" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="用途" width="130rpx" slot="use" align="center">
                <template slot-scope="{ row }">
                    <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
                        <div class="Remarks">
                            {{ row.redActivityName || "- -" }}
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="账单备注" width="130rpx" slot="remark" align="center">
                <template slot-scope="{ row }">
                    <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
                        <div class="Remarks">
                            {{ row.remark }}
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
        </GlobalTable>
        <!--调账上账对话框-->
        <el-dialog title="调账上账" :visible.sync="accountDialogVisible" :close-on-click-modal="false" width="500px"
            @closed="clsoeAccountDialog">
            <div class="addparto"
                    style="display: flex;flex-direction: row;align-items: center;margin-bottom: 10px;">
                    说明：可调整金额不可大于当前门店商户的差异基金余额
                </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
                <el-form-item  label="门店商名称" style="margin:0;" prop="companyId">
                    <el-select v-model="ruleForm.companyId" lterable clearable @change="handleChangeInquiry" filterable class="eleme">
                        <el-option v-for="item in InquirySelectList" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="输入调账金额" prop="price">
                    <el-input v-model="ruleForm.price" placeholder="请输入大于等于0的数,可保留2位小数"
                        @input="limitInput($event, 'price')"></el-input>
                </el-form-item>
                <p style="margin-bottom: 20px;margin-left: 110px;margin-top: 5px;color: #666;" v-if="ruleForm.companyId">
                    当前门店商户差异基金余额:¥{{ diffFundBalance }}元</p>
                <el-form-item label="谷歌验证码" prop="command">
                    <el-input v-model="ruleForm.command" placeholder="请输入谷歌验证码"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click.native="clsoeAccountDialog">取消</el-button>
                <el-button type="primary" @click.native="submit" :loading="submitloading">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import _api from "@/utils/request";
import { mode } from "crypto-js";
import { _http } from "../../../utils/request/bill";
export default {
    name: "Table",
    props: {
    },
    data() {
        return {
            isExport: false,
            loading: false,
            seachDataList: [],
            page: {
                pageNum: 1,
                pageSize: 10
            },
            pageNum: 1,
            tableColumns1: [
                { slotName: "uniqNo" },
                { slotName: "shop" },
                { slotName: "opt" },
                { slotName: "title" },
                { label: "账单时间", prop: "createTime" },
                { slotName: "orderNo" },
                { slotName: "amount" },
                { slotName: "nowBalance" },
                { slotName: "redbageBalance" },
                { slotName: "use" },
                { slotName: "remark" }
            ],
            accountDialogVisible: false,//调账对话框
            ruleForm: {
                price: '',
                command: '',
                companyId: "",
            },
            rules: {
                price: [
                    { required: true, message: '请输入大于等于0的数,可保留2位小数', trigger: 'blur' },
                ],
                command: [
                    { required: true, message: '请输入谷歌验证码', trigger: 'blur' }
                ],
                companyId: [
                    { required: true, message: '请选择门店商', trigger: 'blur' }
                ]
            },
            InquirySelectList: [],
            diffFundBalance: 0,
            submitloading: false,
        };
    },
    created() {
        this.handleCurrentChange();
        _api.getrchantSelectList().then((res) => {
            if (res.code === 1) {
                this.InquirySelectList = res.data
            }
        })
    },
    computed: {
        SeachParams() {
            return this.$store.state.tagsView.SeachParams
        },
    },
    watch: {
        SeachParams(newVal) {
            console.log(newVal)
            this.page.pageNum = 1;
            this.handleCurrentChange()
        },
    },
    methods: {
        // 表格切换页面
        handleCurrentChange(val) {
            if (val) {
                this.pageNum = val
                this.page.pageNum = val;
            }
            if (!this.SeachParams.startTime) {
                return false
            }
            const baseRequest = {
                uniqNo: this.SeachParams.uniqNo,
                orderNo: this.SeachParams.orderNo,
                serType: this.SeachParams.serType,
                startTime: this.SeachParams.startTime,
                endTime: this.SeachParams.endTime,
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
                accountType: "companyRedActivity",
                companyId: this.SeachParams.companyId,
                opt:this.SeachParams.opt
            };
            this.loading = true
            _http.dispatchGetRedBageList(baseRequest).then(res => {
                if (res.code === 1) {
                    this.seachDataList = res.data.ipage.records;
                    this.seachDataList.forEach(ele => {
                        ele.merchantName = ele.merchantName || '--'
                    });
                    this.page.total = res.data.ipage.total;
                    this.page.pageNum = res.data.ipage.current;
                    this.loading = false
                }
            }).finally(() => {
                if (typeof this.SeachParams.disuseLoding == "function") {
                    this.SeachParams.disuseLoding();
                }
            });
        },
        toOrderDetails(row) {
            this.$router.push({ path: "/RecallOrder/Details", query: { type: 'edit', id: row.orderNo } })
        },
        //打开上账弹框
        handBtn() {
            this.accountDialogVisible = true
        },
        // 导出账单
        clickExcel() {
            let SeachParams = this.$store.state.tagsView.SeachParams
            console.log(SeachParams);
            if (!SeachParams.startTime) {
                this.$message.error('请先选择账单时间（31天内）')
                return false
            }
            if ((SeachParams.endTime / 1000 - SeachParams.startTime / 1000 + 1) / 60 / 60 / 24 / 31 > 1) {
                this.$message.error('选择的账单时间超过31天，请重新选择')
                return false
            }
            this.isExport = true
            const baseRequest = {
                uniqNo: this.SeachParams.uniqNo,
                orderNo: this.SeachParams.orderNo,
                serType: this.SeachParams.serType,
                startTime: this.SeachParams.startTime,
                endTime: this.SeachParams.endTime,
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
                accountType: "companyRedActivity",
                companyId: this.SeachParams.companyId,
            };
            _http.dispatchExport(baseRequest).then((res) => {
                const content = res;
                const blob = new Blob([content]);
                let reader = new FileReader(); // 创建读取文件对象
                reader.addEventListener("loadend", () => {
                    try {
                        let data = JSON.parse(reader.result);
                        if (data.code === 0) {
                            this.$message.error(data.msg || "服务异常");
                        }
                    } catch (err) {
                        const fileName = "红包账单.xlsx"
                        if ("download" in document.createElement("a")) {
                            // 非IE下载
                            const elink = document.createElement("a");
                            elink.download = fileName;
                            elink.style.display = "none";
                            elink.href = URL.createObjectURL(blob);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                        } else {
                            // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    }
                });
                reader.readAsText(blob, "utf-8");
                this.isExport = false
            }).catch(err => {
                console.log(err, 9999)
                console.log("97899999");
                this.isExport = false
            });
        },
        //关闭调账对话框
        clsoeAccountDialog() {
            this.ruleForm.companyId = ""
            this.ruleForm.price = ""
            this.ruleForm.command = ""
            this.submitloading=false
            this.accountDialogVisible = false
            this.$refs.ruleForm.resetFields();
        },
        //提交分账
        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        amountPrice: this.ruleForm.price,
                        command: this.ruleForm.command,
                        companyId: this.ruleForm.companyId
                    }
                    this.submitloading = true
                    _http.dispatchTransDiffFundToRedActivity(params).then(res => {
                        this.submitloading = false
                        if (res.code == 1) {
                            this.$message.success("调账上账成功")
                            this.ruleForm.companyId = ""
                            this.ruleForm.price = ""
                            this.ruleForm.command = ""
                            this.diffFundBalance = 0
                            this.accountDialogVisible = false
                            this.$refs.ruleForm.resetFields();
                            this.handleCurrentChange();
                        }
                    }).catch(err => {
                        this.submitloading = false
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //输入限制
        limitInput(value, name) {
            console.log(value);
            let str = (value && value.split("")) || [];
            let reg1 = /\d/;
            let reg2 = /\./;
            // 第一个字符不能为小数点
            if (str[0] == ".") {
                this.ruleForm[name] = "";
                return;
            }
            // 过滤掉除数字和小数点外的字符
            value = str.filter((e) => reg1.test(e) || reg2.test(e));
            // 匹配小数点后只能有两位小数
            let valJoin = value.join("");
            this.ruleForm[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || null;
        },
        //
        handleChangeInquiry(val) {
            console.log(val)
            if (val != '') {
                // this.storeId = ''
                // this.getStoreSelectList()
                this.getCompanyAccount()
            }
        },
        getCompanyAccount() {
            _http.dispatchGetCompanyAccount({ companyId: this.ruleForm.companyId }).then(res => {
                if (res.code == 1) {
                    this.diffFundBalance = res.data.companyAccount.diffFundBalance
                }
            }).catch(err => {
                console.log(err)
            })
        },
            // 上账/下账 点击
    handBtn1(type) {
      let routeData = this.$router.resolve({
        path: "/payment/setAccount",
        query:{type},
      });
      window.open(routeData.href, "_blank");
    },
    },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

    // 备注说明
    .Remarks {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; //控制行数
        overflow: hidden;
    }
}

.addparto {
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
}

/deep/.el-form-item__label {
    text-align: left !important;
}

/deep/ .el-form-item:nth-child(1) {
    margin-bottom: 20px !important;
}
/deep/ .eleme .el-input{
    width: 350px;
}
</style>